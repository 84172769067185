import Vue from 'vue';
import Router from 'vue-router';
import { authorizedNavigation } from '../utility';
import beforeEach from './navigationGuards';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: () => authorizedNavigation()[0].link,
    },
    {
      path: '*',
      redirect: '/unauthorized',
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import(/* webpackChunkName: "unauthorized" */ '@/pages/Unauthorized'),
    },
    {
      path: '/companies',
      name: 'companies',
      component: () => import(/* webpackChunkName: "companies" */ '@/pages/Companies'),
      meta: { isAuthenticated: true },
      children: [
        {
          path: '/companies',
          name: 'companiesList',
          component: () => import(/* webpackChunkName: "companiesList" */ '@/components/CompaniesList'),
          meta: { isAuthenticated: true },
        },
        {
          path: '/companies/partners',
          name: 'companiesPartners',
          component: () => import(/* webpackChunkName: "companiesPartners" */ '@/components/CompaniesPartners'),
          meta: { isAuthenticated: true },
        },
      ],
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import(/* webpackChunkName: "reports" */ '@/pages/Reports'),
      meta: { isAuthenticated: true },
      children: [
        {
          path: '/reports',
          name: 'reportsGamesList',
          component: () => import(/* webpackChunkName: "reportsGamesList" */ '@/components/ReportsGamesList'),
          meta: { isAuthenticated: true, tenantMultipick: true },
        },
        {
          path: '/reports/players',
          name: 'reportsPlayersList',
          component: () => import(/* webpackChunkName: "reportsPlayersList" */ '@/components/ReportsPlayersList'),
          meta: { isAuthenticated: true, tenantMultipick: true },
        },
        {
          path: '/reports/players/:playerName/:playerId',
          name: 'reportsPlayerDetails',
          // eslint-disable-next-line max-len
          component: () => import(/* webpackChunkName: "reportsPlayerDetails" */ '@/components/ReportsPlayerDetails'),
          meta: { isAuthenticated: true },
        },
      ],
    },
    {
      path: '/games',
      name: 'games',
      component: () => import(/* webpackChunkName: "games" */ '@/pages/Games'),
      meta: { isAuthenticated: true },
      children: [
        {
          path: '/games',
          name: 'gamesList',
          component: () => import(/* webpackChunkName: "gamesList" */ '@/components/GamesList'),
          meta: { isAuthenticated: true },
        },
        {
          path: '/games/:companyName/:companyId/:gameName/:gameId',
          name: 'gameRounds',
          component: () => import(/* webpackChunkName: "gameRounds" */ '@/components/GameRounds'),
          meta: { isAuthenticated: true },
        },
      ],
    },
    {
      path: '/players',
      name: 'players',
      component: () => import(/* webpackChunkName: "players" */ '@/pages/Players'),
      meta: { isAuthenticated: true },
      children: [
        {
          path: '/players',
          name: 'playersList',
          component: () => import(/* webpackChunkName: "playersList" */ '@/components/PlayersList'),
          meta: { isAuthenticated: true },
        },
        {
          path: '/players/tags',
          name: 'playersTags',
          component: () => import(/* webpackChunkName: "playersTags" */ '@/components/PlayersTags'),
          meta: { isAuthenticated: true },
        },
      ],
    },
    {
      path: '/promotions',
      name: 'promotions',
      component: () => import(/* webpackChunkName: "promotions" */ '@/pages/Promotions'),
      meta: { isAuthenticated: true },
      children: [
        {
          path: '/promotions',
          name: 'promotionsList',
          component: () => import(/* webpackChunkName: "promotionsList" */ '@/components/PromotionsList'),
          meta: { isAuthenticated: true },
        },
        {
          path: '/promotions/jackpot',
          name: 'promotionsJackpot',
          component: () => import(/* webpackChunkName: "promotionsJackpot" */ '@/components/PromotionsJackpot'),
          meta: { isAuthenticated: true, tenantMultipick: true },
        },
        {
          path: '/promotions/details/:promotionName/:promotionId/:companyId',
          name: 'promotionDetails',
          component: () => import(/* webpackChunkName: "promotionDetails" */ '@/components/PromotionDetails'),
          meta: { isAuthenticated: true },
        },
        {
          path: '/promotions/players/:promotionName/:promotionId/:companyId',
          name: 'promotionPlayers',
          component: () => import(/* webpackChunkName: "promotionPlayers" */ '@/components/PromotionPlayers'),
          meta: { isAuthenticated: true },
        },
      ],
    },
    {
      path: '/developers',
      name: 'developers',
      component: () => import(/* webpackChunkName: "developers" */ '@/pages/Developers'),
      meta: { isAuthenticated: true },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import(/* webpackChunkName: "users" */ '@/pages/Users'),
      meta: { isAuthenticated: true },
    },
    {
      path: '/assets',
      name: 'assets',
      component: () => import(/* webpackChunkName: "assets" */ '@/pages/Assets'),
      meta: { isAuthenticated: true },
      children: [
        {
          path: '/assets',
          name: 'assetsGamesList',
          component: () => import(/* webpackChunkName: "assetsGamesList" */ '@/components/AssetsGamesList'),
          meta: { isAuthenticated: true },
        },
      ],
    },
  ],
});

beforeEach(router);

export default router;
