import axios from 'axios';
import state from '../store/state';

/*
Change baseURL to suit your project needs.
*/
const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000,
});

http.interceptors.request.use((config) => {
  const paramReasign = config;
  const { token } = state.keycloak;
  paramReasign.headers.authorization = `Bearer ${token}`;
  if (state.acceptHeader) {
    paramReasign.headers.accept = state.acceptHeader;
  }

  return paramReasign;
}, (error) => Promise.reject(error));

export default http;
