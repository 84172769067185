<template>
  <div>
    <v-text-field v-model="inputValue"
                  outlined
                  dense
                  :disabled="payload.disabled"
                  :label="payload.label"
                  :hint="payload.hint"
                  type="number"
                  :rules="getRules(payload.rules)"
                  :hide-details="payload.hideDetails"
                  :ref="payload.inputType"
                  @input="getCorespondingAction(payload.inputAction)"
                  @blur="getCorespondingAction(payload.blurAction, payload.inputType)"
                  :validate-on-blur="payload.validateOnBlur"
                  :required="payload.required">
    </v-text-field>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import {
  isDecimal,
  isEntered,
  isLarger,
  isRatio,
  isSmaller,
  noDecimal,
} from '@/utility';

export default {
  name: 'gameSettingsInput',
  props: [
    'currency',
    'gameType',
    'payload',
    'settings',
  ],
  data() {
    return {
      inputValue: null,
    };
  },
  methods: {
    ...mapActions([
      'setCurrencyValidation',
    ]),
    getCorespondingAction(action, type) {
      this.setCurrencyValidation({ type, currency: this.currency });
      if (action) {
        this[action]();
      }
    },
    getRules(rules) {
      return this[rules];
    },
    updateInputs() {
      this.$emit('updateInputs', this.gameType, this.currency, this.payload.inputType, this.inputValue);
    },
    ratioForSlingshot(min, max) {
      return isEqual(this.gameType, 'Slingshot') ? isRatio(min, max,
        this.translations.invalidRangeErrorLabel) : true;
    },
  },
  computed: {
    ...mapGetters([
      'currencyValidation',
      'translations',
    ]),
    minBetAmountRules() {
      return [
        isEntered(this.inputValue,
          this.translations.amountErrorLabel),
        isSmaller(this.settings.currencies[this.currency].maxBetAmount,
          this.translations.settingsStakeErrorLabel),
        isDecimal(this.translations.decimalsAllowedErrorLabel),
        this.ratioForSlingshot(this.inputValue,
          this.settings.currencies[this.currency].maxBetAmount),
      ];
    },
    maxBetAmountRules() {
      return [
        isEntered(this.inputValue,
          this.translations.amountErrorLabel),
        isLarger(this.settings.currencies[this.currency].minBetAmount,
          this.translations.settingsStakeErrorLabel) || false,
        isDecimal(this.translations.decimalsAllowedErrorLabel),
        this.ratioForSlingshot(this.settings.currencies[this.currency].minBetAmount,
          this.inputValue),
      ];
    },
    maxWinAmountRules() {
      if (!this.inputValue) {
        return [true];
      }
      return [
        isLarger(0, this.translations.maxWinAmountErrorLabel),
        noDecimal(this.translations.noDecimalsAllowedErrorLabel),
      ];
    },
    minBetAmountPerCombinationRules() {
      return [
        isEntered(this.inputValue,
          this.translations.amountErrorLabel),
        isSmaller(this.settings.currencies[this.currency].minBetAmount,
          this.translations.smallerComboErrorLabel),
        isDecimal(this.translations.decimalsAllowedErrorLabel),
      ];
    },
  },
  watch: {
    settings: {
      handler(newValue) {
        if (newValue && newValue.currencies) {
          this.inputValue = newValue.currencies[this.currency][this.payload.inputType];
        }
      },
      deep: true,
      immediate: true,
    },
    currencyValidation: {
      handler() {
        if (this.$refs.minBetAmount) {
          this.$refs.minBetAmount.validate();
        } else if (this.$refs.maxBetAmount) {
          this.$refs.maxBetAmount.validate();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
