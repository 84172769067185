export default {
  companies: [],
  partners: [],
  games: [],
  selectedCompany: {},
  selectedPartner: {},
  toggleDetailsModal: false,
  detailsModalConfig: {},
  companyGames: [],
  currencies: ['BAM', 'EUR', 'USD', 'CHF'],
  languages: ['English', 'Romanian', 'Portuguese', 'Kinyarwanda', 'Quechua'],
  selectedGame: {},
  companyGameRounds: [],
  companyGameDetails: [],
  selectedRound: {},
  players: [],
  tags: [],
  playerTags: [],
  playerTagDetails: [],
  assets: [],
  selectedAssetGame: {},
  bootstrap: {},
  reportsGames: [],
  selectedReportsGame: {},
  selectedPromotion: {},
  promotions: [],
  promotionPlayers: [],
  promotionDetails: [],
  reportsPlayers: [],
  rowsCount: [10, 25, 50, 100],
  thumbnail: process.env.VUE_APP_API_ASSETS,
  translations: undefined,
  pusherMessage: {},
  snackbarNotification: {},
  isMobile: false,
  toolbarTitle: '',
  acceptHeader: '',
  cancelControllers: [],
  loading: false,
  importFile: null,
  currencyValidation: {},
  promotionErrorList: {},
  unifiedCurrency: JSON.parse(localStorage.getItem('unifiedCurrency')),
  gameAssetsDetails: {
    data: [],
    pagination: {},
  },
  uploadAssetsDialog: false,
  navigation: [
    {
      title: 'games',
      icon: 'mdi-apps',
      link: '/games',
      mobile: true,
      roles: ['owner', 'admin', 'manager'],
    }, {
      title: 'reports',
      icon: 'mdi-note-text',
      link: '/reports',
      mobile: true,
      roles: ['owner', 'manager'],
    }, {
      title: 'companies',
      icon: 'mdi-account-multiple',
      link: '/companies',
      mobile: true,
      roles: ['owner', 'admin'],
    }, {
      title: 'players',
      icon: 'mdi-account',
      link: '/players',
      mobile: true,
      roles: ['owner', 'admin', 'manager'],
    }, {
      title: 'promotions',
      icon: 'mdi-tag',
      link: '/promotions',
      mobile: true,
      roles: ['owner', 'admin', 'manager'],
    }, {
      title: 'developers',
      icon: 'mdi-wrench',
      link: '/developers',
      mobile: false,
      roles: ['owner', 'admin', 'developer'],
    }, {
      title: 'assets',
      icon: 'mdi-briefcase-variant',
      link: '/assets',
      mobile: false,
      roles: ['owner', 'admin', 'manager', 'developer'],
    },
  ],

  /* ----------------- keycloak ---------------- */
  keycloak: null,
  config: {
    url: process.env.VUE_APP_KEYCLOAK_AUTHORIZATION_SERVER_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  },
  initOptions: {
    responseType: 'code',
    flow: 'standard',
    enablePkce: true,
    pkceMethod: 'S256',
    onLoad: 'login-required',
  },
};
