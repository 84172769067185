import { find } from 'lodash';
import { authorizedNavigation } from '../utility';
import store from '../store';

export default function beforeEach(router) {
  router.beforeEach((to, from, next) => {
    if (to.meta.isAuthenticated) {
      if (!store.getters.keycloakState.authenticated) {
        store.dispatch('keycloakLogout');
      } else if (find(authorizedNavigation(), ['link', to.matched[0].path])) {
        store.dispatch('cancelPendingRequests');
        next();
      } else {
        next({ name: 'unauthorized' });
      }
    } else {
      next();
    }
  });
}
